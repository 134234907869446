<div class="modal-body">
    <app-form-error-messages [customErrorMessages]="errorMessages"
                             [submitted]="submitted"></app-form-error-messages>
    <div class="callInNumberSetupWrapper" *ngIf="!loading">
        <div class="row">
            <div class="col">
                <h4>{{'portal.portal' | translate}}</h4>
                <div class="input-group">
                    <input type="text"
                           class="form-control-plaintext"
                           id="portalName"
                           value="{{portalToSetup.entityName}}"
                           readonly>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col">
                <h4>
                    {{'portal.callInNumber.announcementsNumber' | translate}}
                    <i class="fa fa-spinner fa-spin" *ngIf="loadingAnnouncementsInfo"></i>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="!loadingAnnouncementsInfo">
            <div class="col-md-6">
                <label class="form-label" for="phoneNumber">{{'portal.callInNumber.current' | translate}}</label>
                <div class="input-group">
                    <input type="text"
                           class="form-control"
                           id="phoneNumber"
                           value="{{formatPhoneNumber(currentPhoneNumber)}}"
                           placeholder="{{'portal.callInNumber.noNumberAssigned' | translate}}"
                           readonly>
                    <ng-container *ngIf="currentPhoneNumber">
                        <button class="btn btn-secondary"
                                (click)="releaseNumber()">{{'portal.callInNumber.releaseNumber' | translate}}</button>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6" *ngIf="isSelectingNew">
                <label class="form-label" for="newPhoneNumber">{{'portal.callInNumber.new' | translate}}
                    <i class="fa fa-question-circle"
                       tooltipPosition="right"
                       pTooltip="{{ (currentPhoneNumber ? 'portal.callInNumber.editTelephoneNumberInstructions' : 'portal.callInNumber.newTelephoneNumberInstructions') | translate }}"></i></label>
                <div class="input-group">
                    <input type="text"
                           class="form-control"
                           id="newPhoneNumber"
                           placeholder="{{'portal.callInNumber.selectAvailableNumber' | translate}}"
                           value="{{formatPhoneNumber(newPhoneNumber)}}"
                           readonly>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="!loadingAnnouncementsInfo">
            <div class="col-md-6 align-self-start" *ngIf="!newPhoneNumber && !isSelectingNew">
                <button class="btn btn-link p-0"
                        (click)="beginPhoneNumberSearch()">{{'portal.callInNumber.configureNewNumber' | translate}}</button>
            </div>
        </div>
        <div *ngIf="isSelectingNew">
            <hr>
            <div class="row">
                <div class="col">
                    <h4>
                        {{'portal.callInNumber.search' | translate}}
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-9">
                            <label class="form-label" class="control-label">{{'portal.callInNumber.numberType' | translate}}</label>
                            <div class="form-check">
                                <input id="local"
                                       class="form-check-input"
                                       type="radio"
                                       name="numberType"
                                       value="Local"
                                       [(ngModel)]="searchFilters.numberType"
                                       required>
                                <label class="form-label" class="form-check-label" for="local">
                                    {{'portal.callInNumber.local' | translate}}
                                </label>
                            </div>
                            <div class="form-check">
                                <input id="tollFree"
                                       class="form-check-input"
                                       type="radio"
                                       name="numberType"
                                       value="TollFree"
                                       (change)="handleNumberTypeChange()"
                                       [(ngModel)]="searchFilters.numberType"
                                       required>
                                <label class="form-check-label form-label" for="tollFree">
                                    {{'portal.callInNumber.tollFree' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label class="form-label" for="countryCode">{{'portal.callInNumber.country' | translate}}</label>
                            <select class="form-select"
                                    id="countryCode"
                                    [(ngModel)]="searchFilters.countryCode">
                                <option *ngFor="let country of countryCodes" value="{{country.isoCode}}">
                                    {{country.countryTypeName}}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 col-md-3">
                            <label class="form-label" for="areaCode">{{'portal.callInNumber.areaCode' | translate}}</label>
                            <input class="form-control"
                                   [disabled]="disableAreaCode()"
                                   type="text"
                                   id="areaCode"
                                   [(ngModel)]="searchFilters.areaCode">
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-9">
                            <label class="form-label" for="contains">{{'portal.callInNumber.containsNumber' | translate}}</label>
                            <input class="form-control"
                                   placeholder="{{'portal.callInNumber.phoneNumberContains' | translate}}"
                                   type="text"
                                   id="contains"
                                   [(ngModel)]="searchFilters.contains">
                        </div>
                        <div class="mb-3 col-md-2 align-self-end">
                            <button class="btn btn-primary"
                                    type="button"
                                    (click)="searchForNumbers()"
                                    [disabled]="searchingPhoneNumbers">
                                {{'portal.callInNumber.search' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <i class="fa fa-spinner fa-spin" *ngIf="searchingPhoneNumbers"></i>
                    <div *ngIf="availableNumbers && availableNumbers.length > 0">
                        <label class="form-label">
                            {{'portal.callInNumber.availableNumbers' | translate}}
                            <i class="fa fa-question-circle"
                               tooltipPosition="right"
                               pTooltip="{{ 'portal.callInNumbers.selectAvailableNumber' | translate }}"></i>
                        </label>
                        <p-listbox
                            [options]="availableNumbers"
                            [(ngModel)]="newPhoneNumber"
                            [metaKeySelection]="false"
                            [listStyle]="{ 'max-height': '208px' }"
                            [style]="{ width: '15rem' }">
                            <ng-template let-availableNumber pTemplate="item">
                                {{ formatPhoneNumber(availableNumber) }}
                            </ng-template>
                        </p-listbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="cancel()" [disabled]="submitted">
        {{'common.cancel' | translate}}
    </button>
    <button class="btn btn-primary" (click)="saveAndUpdate()" [disabled]="!newPhoneNumber || submitted">
        {{'portal.callInNumber.saveAndUpdate' | translate}}
        <i class="fa fa-spinner fa-spin" *ngIf="submitted"></i>
    </button>
</div>
