import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { textFieldMaxLength } from '@app/app.constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-new-api-modal',
	templateUrl: './new-api-modal.component.html',
	styleUrls: ['./new-api-modal.component.scss']
})
export class NewApiModalComponent implements OnInit {
	@Input() integrationName;
	submitted: boolean;
	customErrorMessages = [];
	newApiForm: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder,
		private translate: TranslateService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.integrationName = this.dynamicDialogConfig.data.integrationName;
		this.dynamicDialogConfig.header = this.translate.instant('apiManagement.addApiHeader');
	}

	ngOnInit() {
		this.buildNewTeamForm();
	}

	closeModal() {
		this.submitted = true;

		if (this.newApiForm.valid) {
			this.dynamicDialogRef.close({ integrationName: this.integrationName });
		}
	}

	savedButtonIsDisabled() {
		return !this.integrationName;
	}

	buildNewTeamForm() {
		this.newApiForm = this.formBuilder.group(
			{
				integrationName: [{ value: this.integrationName }, [Validators.required, Validators.maxLength(textFieldMaxLength.tiny)]]
			}
		);
	}

	close() {
		this.dynamicDialogRef.close();
	}
}
