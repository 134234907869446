import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Utility } from '@app/providers/utility';
import { TranslateService } from '@ngx-translate/core';
import { EntityService } from '@app/providers/entity.service';
import { Entity } from '@app/shared/incidents/entity.model';
import { PortalSetting } from '@app/shared/portalSetting.model';
import { entitySettingTypeIds } from '@app/app.constants';
import { MessagingService } from '@app/providers/messaging.service';
import { ModalReturnType } from '@app/app.enum';
import { Country } from '@app/shared/country.model';
import { PortalDetails } from '@app/shared/portal-details.model';
import { UntypedFormBuilder } from '@angular/forms';
import { PhoneNumberService } from '@app/providers/phoneNumber.service';
import { FilterService } from '@app/providers/filter.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-call-in-number-setup-modal',
	templateUrl: './call-in-number-setup-modal.component.html',
	styleUrls: ['./call-in-number-setup-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CallInNumberSetupModalComponent implements OnInit {
	@Input() portalToSetup: Entity;
	currentPhoneNumber: string;
	currentPhoneNumberSid: string;
	currentPhoneNumberObject: any;
	entitySettings: { items: Array<PortalSetting>, entityId: string };
	errorMessages: Array<string>;
	isSelectingNew: boolean;
	countryCodes: Array<Country>;
	loadingAnnouncementsInfo: boolean;
	searchingPhoneNumbers: boolean;
	availableNumbers: Array<string>;
	searchFilters: {
		numberType: string
		countryCode: string
		areaCode: string
		contains: string
	};

	submitted: boolean;
	newPhoneNumber: string;
	loading: boolean = false;

	constructor(public translateService: TranslateService,
		private toastrService: ToastrService,
		private entityService: EntityService,
		private messagingService: MessagingService,
		private filterService: FilterService,
		private phoneNumberService: PhoneNumberService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.searchFilters = {
			numberType: 'Local',
			countryCode: 'US',
			areaCode: '',
			contains: ''
		};

		this.portalToSetup = this.dynamicDialogConfig.data.portalToSetup;
		this.dynamicDialogConfig.header = this.translateService.instant('portal.callInNumber.modalTitle');
	}

	ngOnInit() {
		this.loadingAnnouncementsInfo = true;
		this.entitySettings = {
			entityId: this.portalToSetup.entityId,
			items: []
		};
		this.filterService.initializeMetadata().then((_) => {
			this.load().then((_) => {
				if (this.currentPhoneNumber && this.currentPhoneNumberSid) {
					const requestPhoneNumberParam = this.currentPhoneNumberSid.startsWith('PN') ? this.currentPhoneNumberSid : this.currentPhoneNumber;
					this.configure(requestPhoneNumberParam).then((_) => {

					}).catch((_) => {

					}).then((_) => {
						this.loadingAnnouncementsInfo = false;
					});
				}
				else {
					this.loadingAnnouncementsInfo = false;
				}
			}).catch((_) => {
				this.loadingAnnouncementsInfo = false;
			});
		});
	}

	load(): Promise<any> {
		this.errorMessages = [];
		this.currentPhoneNumber = '';
		this.currentPhoneNumberSid = '';
		this.currentPhoneNumberObject = null;
		this.entitySettings.items = [];
		this.newPhoneNumber = '';
		return this.entityService.getPortalDetails(this.portalToSetup.entityId).then((response: PortalDetails) => {
			response.portalSettings.forEach((portalSetting: PortalSetting) => {
				if (!this.currentPhoneNumber && portalSetting.settingTypeId === entitySettingTypeIds.portalAnnouncementNumber) {
					this.currentPhoneNumber = portalSetting.value;
					this.entitySettings.items.push(portalSetting);
				}

				if (!this.currentPhoneNumberSid && portalSetting.settingTypeId === entitySettingTypeIds.portalAnnouncementSid) {
					this.currentPhoneNumberSid = portalSetting.value;
					this.entitySettings.items.push(portalSetting);
				}
			});
		});
	}

	configure(phoneNumber: string): Promise<any> {
		return this.messagingService.getCallInPhoneNumber(phoneNumber).then((response: any) => {
			if (response.phoneNumberSid && response.phoneNumberSid.length > 0) {
				this.currentPhoneNumberObject = response;
			}
		});
	}

	cancel() {
		this.dynamicDialogRef.close(ModalReturnType.cancel);
	}

	saveAndUpdate() {
		this.errorMessages = [];
		this.submitted = true;

		if (this.currentPhoneNumberObject) {
			this.deregisterCallInNumber(this.currentPhoneNumberSid).then((response) => {
				this.saveEntitySettings().then((response) => {
					this.registerNewCallInNumber(this.newPhoneNumber, this.portalToSetup.entityName).then((_) => {
						this.saveEntitySettings().then((_) => {
							this.toastrService.success(this.translateService.instant('portal.callInNumber.successRegisteringNewNumber'));
							this.dynamicDialogRef.close(ModalReturnType.save);
						});
					}).catch((error: string) => {
						this.submitted = false;
						this.errorMessages.push(this.translateService.instant('portal.callInNumber.errorRegisteringNewNumber'));
					});
				});
			}).catch((error: string) => {
				this.submitted = false;
				this.errorMessages.push(this.translateService.instant('portal.callInNumber.errorDeregisteringExistingNumber'));
			});
		}
		else {
			this.registerNewCallInNumber(this.newPhoneNumber, this.portalToSetup.entityName).then((_) => {
				this.saveEntitySettings().then((_) => {
					this.toastrService.success(this.translateService.instant('portal.callInNumber.successRegisteringNewNumber'));
					this.dynamicDialogRef.close(ModalReturnType.save);
				});
			}).catch((error: string) => {
				this.submitted = false;
				this.errorMessages.push(this.translateService.instant('portal.callInNumber.errorRegisteringNewNumber'));
			});
		}
	}

	beginPhoneNumberSearch() {
		this.isSelectingNew = true;
		this.countryCodes = this.filterService.getCountries().map((country: Country) => country);
	}

	searchForNumbers() {
		this.searchingPhoneNumbers = true;
		this.availableNumbers = [];
		this.messagingService.getAvailableCallInPhoneNumbers(this.searchFilters.numberType,
			this.searchFilters.countryCode,
			this.searchFilters.areaCode,
			this.searchFilters.contains).then((response: any) => {
			response.availablePhoneNumbers.forEach((phoneNumber: string) => {
				this.availableNumbers.push(phoneNumber);
			});
		}).catch((_) => {

		}).then((_) => {
			this.searchingPhoneNumbers = false;
		});
	}

	handleNumberTypeChange() {
		if (this.searchFilters.numberType && this.searchFilters.numberType.toLowerCase() === 'tollfree') {
			this.searchFilters.areaCode = '';
		}
	}

	disableAreaCode() {
		const countryCode = this.searchFilters.countryCode || '';
		return this.searchFilters.numberType.toLowerCase() !== 'local' || countryCode.toLowerCase() !== 'us';
	}

	releaseNumber() {
		this.loadingAnnouncementsInfo = true;
		this.deregisterCallInNumber(this.currentPhoneNumberSid).then((response: any) => {
			this.toastrService.success(this.translateService.instant('portal.callInNumber.phoneNumberReleaseSuccess', { currentPhoneNumber: this.currentPhoneNumber }));
			this.saveEntitySettings().then((response) => {
				this.load();
			}).catch((error: any) => {
				this.toastrService.error(this.translateService.instant('portal.callInNumber.settingsUpdateError'));
			});
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('portal.callInNumber.errorDeregisteringExistingNumber'));
		}).then((_) => {
			this.loadingAnnouncementsInfo = false;
		});
	}

	/*
     * Helper Functions
     */

	deregisterCallInNumber(phoneNumberSid): Promise<any> {
		return this.messagingService.releaseCallInPhoneNumber(phoneNumberSid).then((response: any) => {
			this.setEntitySettingValue(this.portalToSetup.entityId, entitySettingTypeIds.portalAnnouncementNumber, '');
			this.setEntitySettingValue(this.portalToSetup.entityId, entitySettingTypeIds.portalAnnouncementSid, '');
		});
	}

	registerNewCallInNumber(phoneNumber: string, portalName: string) {
		return this.messagingService.addCallInPhoneNumber(this.portalToSetup.entityId, phoneNumber, portalName).then((response: any) => {
			this.setEntitySettingValue(this.portalToSetup.entityId, entitySettingTypeIds.portalAnnouncementNumber, phoneNumber);
			this.setEntitySettingValue(this.portalToSetup.entityId, entitySettingTypeIds.portalAnnouncementSid, response.phoneNumberSid);
		});
	}

	setEntitySettingValue(entityId: string, settingTypeId: string, settingValue: string) {
		const settingKeyFound = this.entitySettings.items.find((setting: PortalSetting) => setting.settingTypeId === settingTypeId);
		if (!settingKeyFound) {
			this.entitySettings.items.push(<PortalSetting>{
				settingTypeId: settingTypeId,
				value: settingValue
			});
		}
		else {
			settingKeyFound.value = settingValue;
		}
	}

	saveEntitySettings(): Promise<any> {
		return this.entityService.setEntitySettings(this.entitySettings);
	}

	formatPhoneNumber(phoneNumber: string) {
		return this.phoneNumberService.toDisplayFormat(phoneNumber);
	}

	onSelectionChange(event: any) {
		if (event.value && event.value.length === 1) {
			this.newPhoneNumber = event.value[0];
		}
		else {
			this.newPhoneNumber = '';
		}
	}
}
