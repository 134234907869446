<div class="modal-body">
    <form [formGroup]="newApiForm" novalidate>
        <app-form-error-messages [submitted]="submitted"
                                 [customErrorMessages]="customErrorMessages"
                                 [form]="newApiForm">
        </app-form-error-messages>
        <label class="form-label" for="integrationName">{{ 'apiManagement.integrationName' | translate }}</label>
        <div class="input-group">
            <input type="text" [(ngModel)]="integrationName"
                   formControlName="integrationName" name="integrationName" id="integrationName"
                   maxLength="50" class="form-control" required>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{ 'common.cancel' | translate
        }}
    </button>
    <button type="button" class="btn btn-primary" (click)="closeModal()" [disabled]="savedButtonIsDisabled()">{{
        'apiManagement.confirmButtonText' | translate }}
    </button>
</div>
